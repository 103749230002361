<!-- @format -->

<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="val => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ eventLocal.id ? 'Edit' : 'Add' }} event</h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event')
                hide()
              "
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Title -->
            <validation-provider #default="validationContext" name="Date Title" rules="required">
              <b-form-group label="Event title" label-for="event-title">
                <b-form-input id="event-title" v-model="eventLocal.title" autofocus :state="getValidationState(validationContext)" trim placeholder="Please enter the event title" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider #default="validationContext" name="Select creation date" rules="required">
              <b-form-group label="Select the event start time" label-for="start-date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.start" class="form-control" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" placeholder="Please Select the event start time" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider #default="validationContext" name="Calendar end time" rules="required">
              <b-form-group label="Select the event end time" label-for="end-date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.end" class="form-control" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" placeholder="Please Select the event end time" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Event record url">
              <b-form-group label="Event Website" label-for="event-url">
                <b-form-input id="event-url" placeholder="Please entercthe Event Website(Option)" v-model="eventLocal.url" autofocus :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Upload image -->
            <validation-provider #default="validationContext" name="Upload image">
              <b-form-group label="Upload the picture" label-for="event-image">
                <b-form-file id="event-image" v-model="eventLocal.image" @change="handleFileUpload" placeholder="Please upload the picture(Option)" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click.prevent="submitAddProductTwo" v-if="eventLocal.id">
                <!-- {{ eventLocal.id ? "Update" : "Add " }} -->
                Edit
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click.prevent="submitAddProduct" v-else>
                <!-- {{ eventLocal.id ? "Update" : "Add " }} -->
                Add
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click.prevent="submitAddProduct2"
              >
                Reset
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BFormFile } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs, reactive, getCurrentInstance } from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BFormFile,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  // data() {
  //   return {
  //     required,
  //     email,
  //     url,
  //   };
  // },

  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const formData = reactive({
      start: '',
      end: '',
      title: '',
      img_url: '',
    })

    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)
    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(resetEventLocal, props.clearEventData)
    clearFormData.value = clearForm

    const submitAddProduct = () => {
      store
        .dispatch('calendar/addEvent', {
          start: eventLocal.value.start,
          end: eventLocal.value.end,
          title: eventLocal.value.title,
          url: eventLocal.value.url,
          img_url: formData.img_url,
        })
        .then(res => {
          if (res.code == 0) {
            proxy.$bvToast.toast(res.msg)
            setTimeout(() => {
              location.reload()
            }, 1000)
          }
        })
    }
    const submitAddProductTwo = () => {
      store
        .dispatch('calendar/updateEvent', {
          id: eventLocal.value.id,
          start: eventLocal.value.start,
          end: eventLocal.value.end,
          title: eventLocal.value.title,
          url: eventLocal.value.url,
        })
        .then(res => {
          if (res.code == 0) {
            proxy.$bvToast.toast(res.msg)
            setTimeout(() => {
              location.reload()
            }, 1000)
          }
        })
    }

    // Upload image
    const handleFileUpload = e => {
      const file = e.target.files[0]
      const formDatas = new FormData()
      formDatas.append('image', file)
      axiosIns.post('upload/uploadAvatar', formDatas).then(res => {
        if (res.code == 0) {
          formData.img_url = res.data.url
          emit('update:event', props.event)
        }
      })
    }
    return {
      handleFileUpload,
      // Add New Event
      eventLocal,
      reactive,
      formData,
      calendarOptions,
      onSubmit,
      guestsOptions,
      submitAddProduct,
      submitAddProductTwo,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
